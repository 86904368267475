import React from 'react';

import { Layout } from '@layouts';
import { Markdown, Section } from '@components';
import { useSeo, useAbout } from '@queries';

const AboutPage = () => {
  const { description_about } = useSeo();
  const { _rawContent } = useAbout();

  const siteTitle = 'O mnie';

  return (
    <Layout title={siteTitle} description={description_about}>
      <Section main heading={siteTitle} spaceTop>
        <Markdown blocks={_rawContent} />
      </Section>
    </Layout>
  );
};

export default AboutPage;
